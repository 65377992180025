import React from 'react';

import './awardBox.scss';

const AwardBox = ({ title, preTitle, image, postTitle }) => {
  return (
    <div className="AwardBox">
      <div style={{
        backgroundImage: `url(${image})`
      }} className="AwardBox__image"></div>
      <div className="AwardBox__content">
        <p className="AwardBox__pre-title">{ preTitle }</p>
        <h3 className="AwardBox__title">{ title }</h3>
        <p className="AwardBox__post-title">{postTitle}</p>
      </div>
    </div>
  );
};

export default AwardBox;
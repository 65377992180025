import React from 'react';

import './workBox.scss';

const WorkBox = ({ date, title, company, content, logo, workPeriod, companyEstablishedDate, skills }) => {
  return (
    <div className="WorkBox">
      <p className="WorkBox__date">{ date }</p>
      <p className="WorkBox__title">{ title }</p>
      <div className="WorkBox__text" dangerouslySetInnerHTML={{ __html: content }} />
      <img className="WorkBox__image" src={logo} />
      <ul className="WorkBox__company-info">
        <li>Company: { company }</li>
        <li>Company established: { companyEstablishedDate }</li>
        <li>My work period: { workPeriod }</li>
      </ul>
      { skills && (
        <div className="WorkBox__skills">
          <p>Skills honed:</p>
          <ul>
            { skills.map(item => <li key={item}>{ item }</li> )}
          </ul>
        </div>
      ) }
    </div>
  );
};

export default WorkBox;
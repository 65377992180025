import React from 'react';
import { graphql, useStaticQuery } from "gatsby"
import WorkBox from '../components/workBox/workBox';
import SkillBox from '../components/skillBox/skillBox';
import EducationBox from '../components/educationBox/educationBox';
import AwardBox from '../components/awardBox/awardBox';
import Layout from '../components/layout/layout';
import { renderRichText } from "gatsby-source-contentful/rich-text"

import '../styles/aboutPage.scss';

const AboutPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query AboutPage {
      allContentfulEducation {
        edges {
          node {
            id
            name
            startYear
            endYear
            description {
              description
            }
          }
        }
      }
      allContentfulJob {
        edges {
          node {
            id
            name
            startDate
            endDate
            company
            year
            establishedYear
            workingUntilNow
            description {
              description
            }
            skills
            image {
              id
              file {
                url
              }
            }
          }
        }
      }
      allContentfulSkill {
        edges {
          node {
            id
            name
            image {
              file {
                url
              }
            }
            period
          }
        }
      }
      allContentfulAward {
        edges {
          node {
            id
            name
            subtitle
            postTitle {
              postTitle
            }
            image {
              id
              file {
                url
              }
            }
            order
          }
        }
      }
      allContentfulGeneral {
        edges {
          node {
            id
            bioText {
              bioText
            }
            skillsText {
              raw
            }
          }
        }
      }
    }
  `)

  const renderEducations = () => {
    return data.allContentfulEducation.edges.sort((a, b) => parseInt(b.node.startYear) - parseInt(a.node.startYear)).map(item => (
      <EducationBox
        key={item.node.id}
        date={`${item.node.startYear} - ${item.node.endYear}`}
        title={item.node.name}
        text={item.node.description.description}
      />
    ));
  };

  const renderExperiences = () => {
    return <div>
      {data.allContentfulJob.edges.filter(a => a.node.workingUntilNow).sort((a, b) => b.node.year - a.node.year).map(({ node }) => (
        <WorkBox
          key={node.id}
          date={node.year}
          title={node.name}
          content={node.description.description}
          logo={node.image ? node.image.file.url : ""}
          company={node.company}
          companyEstablishedDate={node.establishedYear}
          workPeriod={`${node.startDate} - ${node.endDate}`}
          skills={node.skills}
        />
      ))}
      {data.allContentfulJob.edges.filter(a => !a.node.workingUntilNow).sort((a, b) => b.node.year - a.node.year).map(({ node }) => (
        <WorkBox
          key={node.id}
          date={node.year}
          title={node.name}
          content={node.description.description}
          logo={node.image ? node.image.file.url : ""}
          company={node.company}
          companyEstablishedDate={node.establishedYear}
          workPeriod={`${node.startDate} - ${node.endDate}`}
          skills={node.skills}
        />
      ))}
    </div>;
  };

  const renderSkills = () => {
    return data.allContentfulSkill.edges.map(({ node }) => (
      <SkillBox
        key={node.id}
        title={node.name}
        subtitle={node.period}
        logo={node.image ? node.image.file.url : ""}
      />
    ));
  };

  const renderAwards = () => {
    return data.allContentfulAward.edges.sort((a, b) => a.node.order - b.node.order).map(({ node }) => (
      <AwardBox
        key={node.id}
        preTitle={node.subtitle}
        title={node.name}
        postTitle={node.postTitle.postTitle}
        image={node.image ? node.image.file.url : ""}
      // organizerLogo={node.acf.organizer_logo.source_url}
      />
    ));
  };

  const generalData = data.allContentfulGeneral.edges[0].node

  return (
    <Layout location={location}>
      <div className="AboutPage">
        <div className="AboutPage__content">
          <h1>About</h1>

          <div
            className="AboutPage__intro-text"
            dangerouslySetInnerHTML={{
              __html: generalData.bioText.bioText,
            }}
          />

          <h2>Awards</h2>

          <div className="Awards">{renderAwards()}</div>

          <h2>Work</h2>

          <div className="AboutPage__work-section">
            <div className="Timeline">
              {renderExperiences()}

              <div className="Timeline__text Timeline__text--start">
                <div className="Timeline__circle" />
              </div>

              <div className="Timeline__text Timeline__text--end">
                <div className="Timeline__circle" />
                <p>Baby Developer</p>
              </div>
            </div>
          </div>

          <h2>Education</h2>

          <div className="AboutPage__education-section">
            <div className="Timeline">
              {renderEducations()}

              <div className="Timeline__text Timeline__text--start">
                <div className="Timeline__circle" />
              </div>

              <div className="Timeline__text Timeline__text--end">
                <div className="Timeline__circle" />
                <p>Baby Student</p>
              </div>
            </div>
          </div>

          {/* <div className="SkillsSection">{renderSkills()}</div> */}
        </div>
      </div>
    </Layout>
  );
};


export default AboutPage
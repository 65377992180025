import React from 'react';

import './educationBox.scss';

const EducationBox = ({ date, title, text }) => {
  return (
    <div className="EducationBox">
      <p className="EducationBox__date">{ date }</p>
      <div dangerouslySetInnerHTML={{ __html: title }} className="EducationBox__title" />
      <div className="EducationBox__text">
        <p>{text}</p>
      </div>
    </div>
  );
};

export default EducationBox;